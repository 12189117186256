import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import { stylesheet } from "typestyle";
import { Head } from "../components/Head";

const styles = stylesheet({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        maxWidth: "50%",
        margin: "auto",
    }
})

/******************************************************************************************
 * This component is currently unused as FAQ's have been inlined into the main page.
 ******************************************************************************************/

export default function Template({ data } : QueryData) {
  const { markdownRemark: post } = data
  if (!post) {
      return null
  }
  return (
    <div className={styles.container}>
      <Head />
      <Helmet title={`${post.frontmatter.title}`} />
      <div>
        <h1>{post.frontmatter.title}</h1>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </div>
  )
}

interface QueryData {
    data: {
        markdownRemark?: {
            html: string
            frontmatter: {
                title: string
            }
        }
    }
}

export const pageQuery = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
            }
        }
    }`